import { createRouter, createWebHistory } from "vue-router";
import { removeToken } from "@/utils/auth";
import wihteRoute from "./whiteRoute";
import store from "@/store/index";
import { Base64 } from "js-base64";
const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
  {
    path: "/wlogin",
    name: "wlogin",
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () => import("@/views/forgetPassword"),
  },
  {
    path: "/home",
    name: "home",
    redirect: "/index",
    component: () => import("@/views/home"),
    children: wihteRoute,
  },
  {
    path: "/coursePlay",
    name: "coursePlay",
    component: () => import("@/views/page/coursePlay"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404"),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes.concat([
    {
      path: "/:catchAll(.*)",
      redirect: "/404",
    },
  ]),
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  if (to.path == "/wlogin") {
    let data = JSON.parse(Base64.decode(to.query.json.replace("*", "+")));
    store
      .dispatch("user/wLogin", data)
      .then((res) => {
        next("/index");
      })
      .catch(() => {
        removeToken();
        next("/index");
      });
  } else {
    next();
  }
});
export default router;
